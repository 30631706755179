import React, { useEffect, useState } from 'react'
import { AuthenticationLayout as Layout } from 'presentation/components/AuthenticationLayout'
import { navigate } from 'gatsby'
import { useSnackbar } from 'presentation/components/Snackbar/useSnackbar'
import { patchPasswordReset } from 'api/patchPasswordReset'
import { deleteQueryParams } from 'scripts/deleteQueryParams'
import { useTranslation } from 'react-i18next'
import { StringParam, useQueryParam } from 'use-query-params'

export const PasswordResetRequest = () => {
  const [snackbar] = useQueryParam('snackbar', StringParam)
  const [email, setEmail] = useState('')
  const [openSnackbar] = useSnackbar()
  const { t } = useTranslation()

  useEffect(() => {
    if (snackbar === 'verifyDeny') {
      openSnackbar([
        t('ログインを拒否しました。'),
        t('安全の為パスワードを再設定しましょう。'),
      ])
      deleteQueryParams(['snackbar'])
    }
  }, [])

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value)
  }
  const handleClick = async () => {
    if (!email) {
      openSnackbar(
        t('メールアドレスが入力されていません。'),
        'blankInputSnackbar',
      )
      return
    }
    const params = { password_reset: { email } }
    const { data, status } = await patchPasswordReset(params)
    if (status === 200 && data.status === 200) {
      navigate('/passwordResetRequested')
      return
    }
    openSnackbar(
      t(
        'パスワード再設定の要求に失敗しました。時間を空けて再度お試しください。',
      ),
    )
  }
  return (
    <Layout>
      <p className="text-xl">{t('再設定する')}</p>
      <div className="w-full px-6 pt-4">
        <p className="text-xs font-semibold text-gray-700 pb-1">
          {t('メールアドレス')}
        </p>
        <input
          className="rounded px-2 w-full h-8 border border-black"
          data-test="email"
          type="email"
          name="email"
          value={email}
          onChange={handleChange}
        />
      </div>
      <div className="w-full px-6 mt-8">
        <button
          className="h-12 font-semibold w-full rounded bg-locked text-white hover:opacity-75"
          data-test="submit"
          onClick={handleClick}
        >
          {t('再設定する')}
        </button>
      </div>
      <div className="mt-5 mb-6">
        <a className="locked-help-link" href="/login">
          {t('ログイン画面はこちら')}
        </a>
      </div>
    </Layout>
  )
}
