import { fullUrl } from 'scripts/fullUrl'
import { PatchPasswordResetResponse } from 'types/ApiResponse'
import { PatchPasswordResetRequest } from 'types/ApiRequest'
import { axiosClient } from 'scripts/api'

export const patchPasswordReset = async (params: PatchPasswordResetRequest) => {
  const res = await axiosClient.patch<PatchPasswordResetResponse>(
    `${fullUrl()}/password_reset`,
    params,
  )
  return res
}
